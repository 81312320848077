@function heightExchange($value) {
  @return calc(#{$value}/22.08 * (100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom))/100);
}


//バージョンアップのボタン無し画面
.versionUpNoButton {
  width: 100%;
  height: 100%;
  background-color: rgba(248, 246, 246, 0.7);

  .alert-wrapper {
    width: 90%;
    height: heightExchange(770);
    background-color: #ffb1ce !important;
    border-radius: heightExchange(60) !important;
    max-width: none !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: -45px;
    box-shadow: unset;

    .alert-head {
      width: 0%;
      height: 0%;
      padding: 0;
    }

    .alert-message {
      color: white;
      font-size: 60px;
      text-align: center;
      padding: 0;
      font-family: NotoSansCJK;
      font-weight: bold;
      line-height: 1.5;
    }
  }
}

//更新中画面
.versionUpdating {
  width: 100%;
  height: 100%;
  background-color: rgb(248 246 246 / 60%);

  .alert-wrapper {
    width: 90%;
    height: heightExchange(770);
    background-color: #ffb1ce !important;
    border-radius: heightExchange(60) !important;
    max-width: none !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -45px;
    box-shadow: unset;

    .alert-head {
      width: 100%;
      height: 70px;
      padding: 0;
      margin-top: 5%;
    }

    .alert-title {
      font-size: 60px !important;
      color: white !important;
      display: flex;
      justify-content: center;
      font-weight: bold;
      font-family: NotoSansCJK
    }

    @media screen and(min-width: 1000px) {
      .alert-message {
        margin-top: 15%;
      }
    }

    .alert-message {
      width: 100%;
      height: 40%;
      background-image: url(/assets/img/loading.png);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      animation: spin 0.8s linear 0s infinite;
    }

    //ローディングのクルクルのアニメーション
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }

    .alert-button-group {
      height: 0;
      width: 0;
    }
  }
}

//バージョンアップ失敗画面
.updateFailed {
  width: 100%;
  height: 100%;
  background-color: rgba(248, 246, 246, 0.7);

  .alert-wrapper {
    width: 90%;
    height: heightExchange(770);
    background-color: #ffb1ce !important;
    border-radius: heightExchange(60) !important;
    max-width: none !important;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: -45px;
    box-shadow: unset;

    .alert-head {
      width: 0%;
      height: 0%;
      padding: 0;
    }

    .alert-message {
      color: white;
      font-size: 60px;
      text-align: center;
      padding: 0;
      margin-top: 30px;
      font-family: NotoSansCJK;
      font-weight: bold;
    }

    .alert-button-group {
      margin-top: heightExchange(210);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column-reverse;

      button {
        width: heightExchange(520);
        margin: 0 auto;
        font-weight: unset;
        height: heightExchange(150);
        border-radius: heightExchange(120);
        background-color: white;
        color: black;
        font-size: 50px;
        letter-spacing: 0;

        .alert-button-inner{
          justify-content: center;
        }
      }
    }
  }
}